import './dialogue.component.styles.scss';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  PropTypes,
  Typography,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { Close } from '@material-ui/icons';
import React, { FunctionComponent, useEffect } from 'react';

export type DialogAction = {
  action: 'Save' | 'Update' | 'Cancel';
  text: string;
  color?: PropTypes.Color;
  isDisabled?: boolean;
  type?: 'submit' | 'normal';
  formId?: string;
};
/* eslint-disable  @typescript-eslint/no-unused-vars */
export type DialogComponentProps = {
  open: boolean;
  actions?: DialogAction[];
  body?: string;
  className?: string;
  dividers?: boolean;
  onClose?: () => void;
  onAction?: (action: DialogAction['action'], index: number) => void;
  title?: string;
} & DialogProps;

export const DialogComponent: FunctionComponent<DialogComponentProps> = ({
  actions,
  body,
  children,
  className,
  onClose,
  onAction,
  open,
  title,
  ...props
}) => {
  useEffect(() => {
    setTimeout(() => {
      const dialogueId = document.getElementById('cpx-dialogue');
      const root = document.getElementById('root');
      if (dialogueId) {
        dialogueId.setAttribute('aria-hidden', 'false');

        const sentinelStart = dialogueId.querySelector('div[data-test="sentinelStart"]');
        const sentinelEnd = dialogueId.querySelector('div[data-test="sentinelEnd"]');

        if (sentinelStart) {
          sentinelStart.setAttribute('tabindex', '-1');
        }
        if (sentinelEnd) {
          sentinelEnd.setAttribute('tabindex', '-1');
        }
      }
      if (root) {
        root.setAttribute('aria-hidden', 'false');
      }
    }, 500);
  }, [open]);

  return (
    <Dialog
      id="cpx-dialogue"
      className={`dialogue-component ${className}`}
      onClose={onClose}
      aria-labelledby={'cpx-dialogue'}
      open={open}
      {...props}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6">{title}</Typography>
          {onClose ? (
            <IconButton aria-label="Close" className="close-button" onClick={onClose}>
              <Close />
            </IconButton>
          ) : (
            ''
          )}
        </Box>
      </DialogTitle>
      <DialogContent dividers={false}>
        {children ? children : <DialogContentText>{body}</DialogContentText>}
      </DialogContent>
      <DialogActions>
        {actions?.map(({ action, color, text, isDisabled, type = 'normal', formId }: DialogAction, index: number) => (
          <Grid item key={`${text}_${index}`}>
            {type === 'submit' ? (
              <Button
                key={index}
                disabled={isDisabled}
                color={color || 'default'}
                variant="contained"
                fullWidth
                type="submit"
                form={formId || 'custom-form'}
              >
                {text}
              </Button>
            ) : (
              <Button
                key={index}
                disabled={isDisabled}
                color={color || 'default'}
                variant="contained"
                fullWidth
                onClick={(e) => {
                  e.preventDefault();
                  onAction && onAction(action, index);
                }}
              >
                {text}
              </Button>
            )}
          </Grid>
        ))}
      </DialogActions>
    </Dialog>
  );
};
